/* eslint-disable react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import MarkdownContent from '../components/MarkdownContent';

import '../components/schedule.css';

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <div>
      <SEO title="Plan Zajęć" />
      <Layout>
        <div className="schedule">
          <h1>{frontmatter.title}</h1>

          <MarkdownContent content={frontmatter.description} />
        </div>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "schedule-page" } }) {
      frontmatter {
        description
        title
      }
    }
  }
`;
